<template>
    <card class="w-full p-3">
        <div class="w-full h-44 flex flex-row justify-start items-center gap-4">
            <div class="w-28 h-full">
                <img v-if="employee.photo" :src="employee.photo" alt="employee photo" class="w-full h-full"/>
                <div v-else class="w-full h-full border rounded-md flex justify-center items-center">
                    <span style="font-size:60px">
                        {{ $getInitials(`${employee.fname} ${employee.lname}`) }}
                    </span>
                </div>
            </div>
            <div class="w-3/4 h-full flex flex-col justify-between tracking-wide">
                <p class="font-bold text-lg text-darkPurple capitalize">
                    {{ employee.fname }} {{ employee.lname }}
                </p>
                <!-- // <p class="w-full flex justify-between items-center" v-feature-request>
                //     <span class="inline-block text-romanSilver font-black text-xs">Group/Division:</span>
                //     <span class="inline-block w-4/5 font-bold text-jet text-sm">xxxx</span>
                // </p> -->
                <p class="w-full flex justify-between items-center">
                    <span class="inline-block text-romanSilver font-black text-xs">Function:</span>
                    <span v-if="employee.orgFunction" class="inline-block w-4/5 font-bold text-jet text-sm">
                        {{ employee.orgFunction.name || "Nil" }}
                    </span>
                </p>
                <p class="w-full flex justify-between items-center">
                    <span class="inline-block text-romanSilver font-black text-xs">Designation:</span>
                    <span v-if="employee.userDesignation" class="inline-block w-4/5 font-bold text-jet text-sm">
                        {{ employee.userDesignation.name || "Nil"}}
                    </span>
                </p>
                <p class="w-full flex justify-between items-center">
                    <span class="inline-block text-romanSilver font-black text-xs">Job Level:</span>
                    <span v-if="employee.userLevel" class="inline-block w-4/5 font-bold text-jet text-sm">
                        {{ employee.userLevel.name || "Nil"}}
                    </span>
                </p>
                <p class="w-full flex justify-between items-center">
                    <span class="inline-block text-romanSilver font-black text-xs">Office Location:</span>
                    <span v-if="employee.employeeOffice" class="inline-block w-4/5 font-bold text-jet text-sm">
                        {{ employee.employeeOffice.city || " " }}
                        {{ employee.employeeOffice.state || "Nil" }}
                    </span>
                </p>
                <p class="w-full flex justify-between items-center">
                    <span class="inline-block text-romanSilver font-black text-xs">Manager:</span>
                    <span v-if="employee.userReportingTo" class="inline-block w-4/5 font-bold text-jet text-sm">
                        {{ employee.userReportingTo.fname }} {{employee.userReportingTo.lname}}
                    </span>
                  <span v-else class="inline-block w-4/5 font-bold text-jet text-sm">
                        -
                    </span>
                </p>
                <p class="w-full flex justify-between items-center">
                    <span class="inline-block text-romanSilver font-black text-xs">Length of Service:</span>
                    <span class="inline-block w-4/5 font-bold text-jet text-sm">
                        {{ $getLengthOfService(employee.resumptionDate) }}
                    </span>
                </p>
            </div>
        </div>
    </card>
  </template>

  <script>
  import Card from "./Card";

  export default {
    components: {
        Card,
    },
    props: {
        employee: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
      return {
      }
    }
  };
</script>
